import axios from '@/utils/request'
let Api = {
	jobList: "/post/list",
	studentSignUp:"/post/signUp",
	studentSignUpCancel:"/post/signUpCancel",
	jobSignupCalculate:"/post/signUp/summary",
	upLoadResume:"/post/uploadResure/",
	deleteResume:"/post/removeResure/",
	confirmWorkingHour:"/workingHours/confirmWorkingHour"
}
//工时确认
export function confirmWorkingHourMb(params) {
	return axios({
		method: 'put',
		url: Api.confirmWorkingHour,
		data: params
	})
}
//上传简历
export function upLoadResumeMb(params,id) {
	return axios({
		method: 'post',
		url: Api.upLoadResume+id,
		data: params
	})
}
//删除简历
export function deleteResumeMb(params,id) {
	return axios({
		method: 'delete',
		url: Api.deleteResume+id,
		params: params
	})
}
//学生已报名岗位统计
export function jobCalculate(params) {
	return axios({
		method: 'get',
		url: Api.jobSignupCalculate,
		params: params
	})
}
// 岗位列表
export function jobListMb(params) {
	return axios({
		method: 'get',
		url: Api.jobList,
		params: params
	})
}
//学生岗位报名
export function studentSignUpMb(params) {
	return axios({
		method: 'post',
		url: Api.studentSignUp,
		data: params
	})
}
//学生岗位取消报名
export function studentSignUpMbCancel(params) {
	return axios({
		method: 'post',
		url: Api.studentSignUpCancel,
		data: params
	})
}
